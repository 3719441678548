// React
import React from "react";

// Internal dependencies
import "./navbar-section.css";


export default class NavbarSection extends React.Component
{
    /* VISUALS */

    render()
    {
        const { classNames, ...otherProps } = this.props;

        return(
            <div className={["navbar-section", ...classNames || []].join(" ")} {...otherProps}>
                {this.props.children}
            </div>
        );
    }
}