// React
import React from "react";

// Gatsby
import { Link } from "gatsby";

// Internal dependencies
import "./navbar-item.css";


export default class NavbarItem extends React.Component
{
    /* VISUALS */

    render()
    {
        const { classNames, to, ...otherProps } = this.props;

        return(
            <Link className={["navbar-item", ...classNames || []].join(" ")} to={to || "/"} {...otherProps}>
                {this.props.label}
            </Link>
        );
    }
}