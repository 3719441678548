// React
import React from "react";

// Internal dependencies
import "./main.css";


export default class Main extends React.Component
{
    /* VISUALS */

    render()
    {
        const { classNames, ...otherProps } = this.props;

        return(
            <main className={["main", ...classNames || []].join(" ")} {...otherProps}>
                {this.props.children}
            </main>
        );
    }
}