// React
import React from "react";

// Internal dependencies
import "./header.css";


export default class Header extends React.Component
{
    /* VISUALS */

    render()
    {
        const { classNames, ...otherProps } = this.props;
        
        return(
            <header className={["header", ...classNames || []].join(" ")} {...otherProps}>
                {this.props.children}
            </header>
        );
    }
}