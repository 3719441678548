// React
import React from "react";

// Gatsby
import { Link } from "gatsby";

// Internal dependencies
import "./navbar-logo.css";


export default class NavbarLogo extends React.Component
{
    /* VISUALS */

    render()
    {
        const { classNames, src, alt, to, ...otherProps } = this.props;

        return(
            <Link className={["navbar-logo-link", ...classNames || []].join(" ")} to={to || "/"} {...otherProps}>
                <img className="navbar-logo-image" src={src} alt={alt || "Logo"} />
            </Link>
        );
    }
}